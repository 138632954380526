
const LOKI_URL="http://192.168.0.192:3101";
const orig = {};
var buffer = [];
var annotateLogFn;
var indexName;

export function installLokiLogging(applicationName,annotateLog){

    //save old console functions
    orig.log = console.log;
    orig.info = console.info;
    orig.warn = console.warn;
    orig.error = console.error;
    annotateLogFn = annotateLog;
    indexName = applicationName;
    

    //bypass sending messages back to server
    console.local = function(message,...args){
                orig.log(message,...args);
            };
    console.log = function(message,...args){
                orig.log(message,...args);
                log("log",message,{additionalInformation:args});
            };
    console.info= function (message,...args) {
                orig.info(message,...args);
                log("info",message,{additionalInformation:args});
            };
    console.warn= function (message,...args) {
                orig.warn(message,...args);
                log("warn",message,{additionalInformation:args});
            };
    console.error= function (message,...args) {
                orig.error(message,...args);
                log("error",message,{additionalInformation:args});
            };

    setInterval(function(){_flushBuffer();},5 * 1000);

}
function log(level,message,log){
    log.level= level;
    log.message= message;
    log.timestamp=  Date.now();

    try{
        JSON.stringify(log.additionalInformation);
    }catch(error){
        console.local("failed to stringify additional arguments: ",error);
        log.additionalInformation="FAILED TO STRINGIFY";
    }

    if(annotateLogFn != null)
        log = annotateLogFn(log);

    buffer.push(log);
    if(buffer.length >= 10){
        _flushBuffer();
    }
}

function _flushBuffer(){
    if(buffer.length === 0)
        return;
    console.local("flushing buffer, size: "+buffer.length);
    try{

        const localBuffer = buffer;
        buffer=[];
        //sendToLoki(localBuffer);
        recordLogs(localBuffer);
    }catch(error){
        console.error("failed to flush buffer: "+error.message,error);
    }

}
function recordLogs(logs){

    return frappe.call("galcom.compass.devices.client_logs",{
        application:indexName,
        logs:logs});
}
function sendToLoki(logs){

    var data = {
        streams:logs.map(log => {
                return {
                    stream:{
                        app:indexName,
                        severity:log.level,
                    },
                    values: [[String(log.timestamp*1000000),JSON.stringify(log)]],
                };
            })
    };
    console.local("loki log: ",JSON.stringify(data));
    return fetch(LOKI_URL+"/loki/api/v1/push",
        {
            //mode:"no-cors",
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type":"application/json",
            }
        }).then((response)=>{
            if(response.error != null && response.error.reason != null){
                orig.error("Failed to record batch of log messages: "+response.error.reason);
            }
        }).catch((error) =>{
            orig.error("Failed to record batch of log messages to "+LOKI_URL+", ",error.message);
        });
}