import { installLokiLogging } from "./loki-logging";

frappe.ready(()=>{

    var ws = frappe.provide("galcom.compass.workstation");
    if( window.ko==null)
        return;

    installLokiLogging("test_station_client",(log)=>{
        log.station_name = ws.view.selectedWorkstation();
        return log;
    });

    ws.view = {
        workstations: ko.observable(),
        workOrders: ko.observable(),
        selectedWorkstation: ko.observable(),
        selectedUser: ko.observable(frappe.session && frappe.session.user),
        selectedWorkOrder: ko.observable(),

        currentDevice: ko.observable(),
        activeTest: ko.observable(),
        tests: ko.observableArray(),
        userMessage: ko.observable(),
        showMessageAck: ko.observable(false),
        allTestsDone: ko.observable(false),
        allTestsPassed: ko.observable(false),
        devicesUpdating: ko.observableArray(),
        workstationMode: ko.observable("test"), // or "reconfig"
        configMessage: ko.observable(),

        page: ko.observable("no-device"),
        previousPage: ko.observable(),

        selectWorkstation: function(data){
            ws.view.selectedWorkstation(data);
            frappe.db.get_list("Compass Workstation",{
                fields:["active_work_order"],
                filters:{
                    name:data
                }
            });
        },
        selectWorkOrder: function(data){
            console.log("selecting workorder: ",data)
            data.qtyDone=ko.observable();
            ws.view.selectedWorkOrder(data);
            ws.view.configMessage(undefined);

            //init with current finished qty
            ws.view.qtyDone().then(data.qtyDone);
        },
    };
    

    ws.view.toggleConfig = function(forceConfig=false){
        console.log("toggle config ",forceConfig);
        if(ws.view.page() === "config" && forceConfig !== true)
            ws.view.page(ws.view.previousPage());
        else{
            ws.view.previousPage(ws.view.page());
            ws.view.page("config");
        }

    }
    ws.view.isTestActive = function(test_name){
        return ws.view.activeTest() && ws.view.activeTest().test_name === test_name;
    }

    ws.view.testStatus = function(test){

        console.log("testStatus: ",test.test_name, test.passed, ws.view.activeTest() && ws.view.activeTest().test_name);

        if(test.passed == null 
            && ws.view.activeTest() != null
            && test.test_name === ws.view.activeTest().test_name)
            return "Pending";
        else if(test.passed == null)
            return "";
        else if(test.passed === true)
            return "Passed";
        else if(test.passed === false)
            return "Failed";
    }
    ws.view.ackMessage = function(){
        ws.view.userMessage(null);
        ws.sendStationMessage( { type:"response", });
        ws.view.page("testing");
    }
    ws.view.rerunTest = function(test){
        console.log("request to re-run test: ",test);
        test.passed = undefined; //remove previous test state
        ws.view.allTestsDone(false);
        ws.triggerTestDataUpdate(test);
        ws.sendStationMessage( {
                type:"command",
                command:"retest",
                test_name: test.test_name,
                mac_address: ws.view.currentDevice() && ws.view.currentDevice().mac_address
            }
        );
       
    }
    ws.view.refreshWO = function(){

        if(!frappe.is_user_logged_in()){
            console.log("User not logged in anymore, reloading");
            location.reload();
        }

        return frappe.db.get_list("Work Order", { 
            fields:["name","item_name","qty","frequencies","sales_order"],
            filters:{
                status: ["in",[ "Not Started","In Process"]],
                in_progress: 1,
                compass_configuration: ['!=',''],
            }
        }).then(result =>{
            //console.log("got wo result: ",result);
            ws.view.workOrders(result);
        });
    }
    ws.view.qtyDone = async function(){
        const workOrderName = ws.view.selectedWorkOrder().name;
        const count = await frappe.db.count("Compass Device",{
            filters:{
                work_order: workOrderName,
                production_done: true
            }
        });
        console.log("qtyDone for "+workOrderName+": "+count);
        return count;
    }
    ws.view.workOrderInfo = function(){
        console.log("getting work order info");
        if(ws.view.workOrders() != null && ws.view.selectedWorkOrder() != null){
            var workOrder = ws.view.workOrders().find( wo => wo.name === ws.view.selectedWorkOrder().name)
            console.log("found work order for info: ",workOrder);
            if(workOrder != null){
                var str = workOrder.item_name
                if(workOrder.frequencies != null && workOrder.frequencies !== "")
                    str=str+" "+workOrder.frequencies;

                return str;
            }
            else return "";
        }
        else 
            return "";
    }
    ws.view.deviceClass = function(device){
        console.log("setting device class", device);
        var status = device.status();
        if(status === "done")
            return "border-success alert-success";
        else if(status.match(/failed/))
            return "border-danger alert-danger";
        else if(status === "interrupted")
            return "border-warning alert-warning";
        else
            return "border-secondary";


    }
    ws.view.changeMode = function(newMode){

        if(! ["test","reconfig","detach","production_test","info","production_info"].includes(newMode))
            return;
        if(ws.view.selectedWorkstation() == null){
            ws.view.configMessage("Please set the station name before changing the mode");
            var sub=ws.view.selectedWorkstation.subscribe(()=>{
                ws.view.configMessage(null);
                sub.dispose();
            });
            return;
        }
        
        console.log("updating workstation mode to "+newMode);

        ws.view.workstationMode(newMode);

        var stationMode = newMode;
        if(newMode === "detach" || newMode == "production_info")
            stationMode = "production_test";  // get it to listen as a global
        else if(newMode === "info")
            stationMode = "test";

        return ws.sendStationMessage({
                type:"command",
                command: "change_mode",
                newMode: stationMode
            });
    }
    ws.view.removeDevice = function(device){
        ws.view.devicesUpdating.remove(device);
    }
    ws.view.timeString = function(date){
        return date.toLocaleString();
    }
    ws.view.displayMode = function(){
        if(ws.view.workstationMode() === "test")
            return "Testing Mode";
        else if(ws.view.workstationMode() === "reconfig")
            return "Config From Stock";
        else if(ws.view.workstationMode() === "detach")
            return "Detach From WO";
        else if(ws.view.workstationMode() === "production_test")
            return "Re-Test Mode";
        else if(ws.view.workstationMode() === "info" || ws.view.workstationMode() === "production_info")
            return "Device Information";
        else
            return "Unknown Mode";
    }


    ////// functions triggered by changes in other observables ////////
    ko.computed(() =>{
        if(ws.view.selectedWorkstation() != null 
            && ws.view.selectedUser() != null 
            && ws.view.selectedWorkOrder() != null){

            frappe.db.set_value("Compass Workstation",ws.view.selectedWorkstation(),
                                 "user",ws.view.selectedUser()).
                then( result => {
                    console.log("saved user name, got result: ",result);
                }).then(()=>{
                    return frappe.db.set_value("Compass Workstation",ws.view.selectedWorkstation(),
                                                "active_work_order",ws.view.selectedWorkOrder().name);
                }).then( result => {
                    console.log("saved work order, got result: ",result);

                    //clear state
                    ws.view.currentDevice(null);
                });
        }
    });
    ko.computed(() =>{
        var tests = ws.view.tests();
        //see if we can find a test without a 'passed' key.

        if(tests.length===0)
            return; //no tests loaded yet

        var test=tests.find( test => test.passed === undefined);
        if(test == null){// if not such test was found, then we've run all tests
            console.log("all tests done");
            ws.view.allTestsDone(true);
        }else
            ws.view.allTestsDone(false);

        //see if we can find a non-passed test (either failed or not run)
        console.log("num tests: "+tests.length);
        console.log("test statuses: "+tests.map(t => t.passed).join(","));
        test=tests.find( test => test.passed !== true);
        if(test == null){
            console.log("all tests passed");
            ws.view.allTestsPassed(true);
            ws.onAllTestsPassed();
        }else
            ws.view.allTestsPassed(false);
    }).extend({rateLimit:50});



    /////// Non-View related functions ////////////////////////
    ws.sendStationMessage=function(message){
        return frappe.call("galcom.compass.devices.messageStation",{
            //mac_address: ws.view.currentDevice().mac_address,
            mac_address:null,
            message: message,
            station_name: ws.view.selectedWorkstation(),
        });
    }
    ws.onAllTestsPassed = function(){
        //put device in post processing list
        // show in sidebar
        // record work order number on device entry in ERP
        

        var device = ws.view.currentDevice();

        console.log("onAllTetsPassed: device "+device.mac_address,device);
        console.log("num updating devices: "+ws.view.devicesUpdating().length);
        //only add if it's not already there
        if(ws.findUpdatingDevice(device.mac_address) == null){
            console.log("putting device in updating list");

            //call ERP to report all tests passed
            if(ws.view.workstationMode() === "production_test"){
                frappe.call("galcom.compass.devices.all_tests_passed",{
                    set_work_order: false,
                    mac_address: device.mac_address}).
                  then(()=>ws.reset());
            }else{
                ws.setDeviceStatus(device,"pending");
                ws.addToSideList(device);

                ws.sendStationMessage( {
                        type:"command",
                        command:"testing_done",
                        mac_address: device.mac_address
                    }
                ).then(() =>{
                    console.log("send command to update firmware");
                    ws.setDeviceStatus(device,"awaiting updates");

                    return frappe.call("galcom.compass.devices.all_tests_passed",{
                        mac_address: device.mac_address});
                }).then(() => {
                    ws.reset();
                    return ws.view.qtyDone().then(ws.view.selectedWorkOrder().qtyDone)
                });
            }
        }

    }
    ws.findUpdatingDevice = function(mac_address){
        return ws.view.devicesUpdating().find( d => d.mac_address === mac_address);
    }
    ws.loadTests = function(){
        var tests = [
            {
                test_name: "test_speaker",
                title: "Speaker Test",
                instructions: "The device will now play a sound.",
                question: "Do you hear the sound?",
                passed_result:"sound heard",
                failed_result: "no sound heard",
            },
            {
                test_name: "test_solar",
                title: "Solar Test",
            },
            {
                test_name: "test_playback",
                title: "Playback Test",
                instructions: "Ensure the headphone jack is plugged in. A sound will play.",
                question: "Do you hear the sound?",
                passed_result:"sound heard",
                failed_result: "no sound heard",
            },
        ];
        ws.view.tests.removeAll();
        if(ws.view.workstationMode() == "production_test"){
            // don't run the test_playback test for this mode
            ws.view.tests.push(tests[0]);
            ws.view.tests.push(tests[1]);
        }else
            ws.view.tests(tests);
    }
    ws.recordTest = function(test_name,passed,test_result ){
        console.log("recording test result for "+test_name+" ",passed,test_result);
        //record result
        frappe.call("galcom.compass.devices.record_test",{
            test_name: test_name,
            mac_address: ws.view.currentDevice().mac_address,
            test_result: test_result,
            passed: passed
        })

    }
    ws.triggerTestDataUpdate=function(test){
        console.log("forcing tests update");
        //TODO: make better use of observable so this is not needed

        //trigger udpate since test are not observables
        var i = ws.view.tests.indexOf(test);
        ws.view.tests.splice(i, 1); // removes the item from the array
        ws.view.tests.splice(i, 0, test); // adds it back
    }
    ws.reset = function(){
        console.log("resetting");
        //clear all device speciific data
        ws.view.page("no-device");
        ws.view.currentDevice(null);
        ws.view.userMessage(null);
        ws.view.activeTest(null);
        ws.view.allTestsDone(false);
        ws.view.allTestsPassed(false);
        ws.loadTests();
    }

    ///// Callbacks from ERP /////////////////////////
    ws.startTest = function(test_name){
        console.log("start test for "+test_name);
        var test = ws.view.tests().find( test => test.test_name === test_name);
        if(test == null){
            console.error("not test found by name "+test_name);
        }else{
            console.log("found test ",test);
            ws.view.userMessage(null);

            test.onPass = () =>{
                console.log("test passed");
                ws.recordTest(test_name, true, test.passed_result);
                ws.view.activeTest(null);
                ws.view.page("testing");
            }
            test.onFail = () => {
                console.log("test failed");
                ws.recordTest(test_name, false, test.failed_result);
                ws.view.activeTest(null);
                ws.view.page("testing");
            }
            ws.view.activeTest(test);
            ws.view.page("test-input");
        }
    }
    ws.updateTestStatus = function(test_name, passed,mac_address){
        // passed comes from python, so the value is likely a string representation
        passed = passed === true || passed === 'True' || passed === "true" ? true:false;

        console.log("updateTestStatus (from ERP): ",test_name, passed);

        var test = ws.view.tests().find( test => test.test_name === test_name);
        if(test != null){
            console.log("updating test status ",test_name,passed);
            test.passed = passed

            //clear any test related UI elements
            ws.view.activeTest(undefined);
            ws.view.userMessage(undefined);
            ws.view.page("testing");

            ws.triggerTestDataUpdate(test);
        }else if( mac_address != null && mac_address !== "" ){
            if(test_name === "firmware_update"){
                if(passed === false){
                    console.log("fimrware update failed")
                    ws.updateDeviceStatus(mac_address,"firmware udpate failed");
                }else{
                    ws.updateDeviceStatus(mac_address,"firmware updated");
                }
 
            //if(test_name === "firmware_update" && passed === false){
             //   console.log("fimrware update failed")
              //  ws.updateDeviceStatus(mac_address,"firmware udpate failed");
            }else if(test_name === "configuration_update"){
                if(passed === true){
                    ws.updateDeviceStatus(mac_address,"done");
                }else{
                    ws.updateDeviceStatus(mac_address,"config udpate failed");
                }
            
            }
        }
    }
    ws.updateDeviceStatus = function(mac_address,status){
        console.log("update device status ",mac_address,status);
        console.log("current device: ",ws.view.currentDevice());

        if(ws.view.currentDevice() && ws.view.currentDevice().mac_address === mac_address){
            // the device currently being tested disconnected, so reset tests
            ws.reset();
        }


        var device = ws.findUpdatingDevice(mac_address);
        if(device != null){
            console.log("found device to update");
            if(status === "disconnected"){
                device.connected(false);
				if(device.status() === "done")
					ws.view.devicesUpdating.remove(device);
            }else{
                ws.setDeviceStatus(device,status);
                device.connected(true);
            }
        }
    }
    
    ws.setDeviceStatus = function(device,status){
        if(device.status == null)
            device.status=ko.observable();
        if(device.lastUpdated == null)
            device.lastUpdated = ko.observable();
        
        device.status(status);
        device.lastUpdated(new Date());
    }
    ws.addToSideList = function(device){
        if(ws.findUpdatingDevice(device.mac_address) == null){
            ws.view.devicesUpdating.push(device);
        }
    }
    ws.showUserMessage = function(message,withAck=false){
        ws.view.userMessage(message);
        ws.view.showMessageAck(withAck);
        ws.view.page("message");
    }

    ws.check_board_type = async function(device){
        // look up items on WO, make sure that given circuit board matches the reported board id of the device
        const wo=ws.view.selectedWorkOrder();
        const boardFunctions = {
            0: ["Radio","Player"],
            2: ["Radio"],
            3: ["Radio","Player"],
            7: ["Player"],
            9: ["Radio"],
            5: ["Radio","Player"],
            6: ["Player"],
            8: ["Radio"],
        }
        const failOperation = function(message){
            //console.error("wrong board id on connected device, required "+itemCode+", but that was not found on the work order");
            console.error("wrong board id on connected device: "+message);
            ws.showUserMessage("Hmm, looks like there is a problem with the circuit board: "+message);
            ws.view.page("message");
            return false;
        }
        console.log("check board type for device ",device);


        if(wo == null)
            return failOperation("No work order set, so can't check board id");

        // We need to check that the given board id provides all functions required by the circuit board on the BOM.
        // If given board provies both functions, pass
        // Search for an item that is taged with our given function.
        // if not found, fail
        // if found, see if it is also tagged with the other function
        // if so, fail, if not, pass

        const providedFunctions = boardFunctions[device.board_id];

        //board id was not defined
        if(providedFunctions == null)
            return failOperation("Could not find list of functions provided by board id "+device.board_id);

        //both functions provided, so we're good regardless of what is on the BOM
        if(providedFunctions.length === 2)
            return true;

        var items = await frappe.db.get_list("Work Order Item",{
            fields:["item_code"],
            parent_doctype:"Work Order",
            filters: { parent:wo.name, 
                "item_code":["in",Object.keys(ws.circuitBoards)]}
        });
        console.log("found items:",items,providedFunctions);


        //no item tagged with required function
        if(items == null || items.length === 0){
            return failOperation("No circuit boards found on work order");
        }else if(items.length > 1){
            return failOperation("More than 1 circuit board found on work order, really not sure what to do with that!");
        }

        const woBoardItem = ws.circuitBoards[items[0].item_code];
        console.log("woBoardItem: ",woBoardItem);

        //both functions required, but we only have one
        if(woBoardItem.hasPlayer && woBoardItem.hasRadio)
            return failOperation("Player and Radio required, but current device has only "+providedFunctions[0]);

        if((woBoardItem.hasPlayer && providedFunctions[0] === "Radio") 
           || (woBoardItem.hasRadio && providedFunctions[0] === "Player"))
            return failOperation("work order item "+woBoardItem.item_code+" requirements are not met by provided function '"
                +providedFunctions[0]+"'");


        return true;
    }
    
    ws.new_device = async function(deviceName){
        // deviceName is the MAC
        console.log("new device registered, "+deviceName);


        if(ws.view.selectedWorkOrder() == null && ! ["detach","info","production_test","production_info"].includes(ws.view.workstationMode())){
            console.log("no work order selected, ignoring new device");
            ws.view.configMessage("Please select a work order before starting test");
            ws.view.toggleConfig();
            return;
        }

        return frappe.db.get_list("Compass Device",{
            fields: ["mac_address","firmware_version","test_results","work_order","production_done","workstation_name","board_id"],
            filters:{
                name: deviceName
            }
        }).then( async devices =>{
            console.log("got devices: ",devices);
            if(devices && devices.length === 1){
                var device = devices[0];
                
                device.status = ko.observable();
                device.connected = ko.observable(true);

                if(ws.view.workstationMode() === "reconfig"){
                    ws.setDeviceStatus(device,"re-configuring");
                    ws.addToSideList(device);
                }else if(ws.view.workstationMode() === "detach"){
                    ws.setDeviceStatus(device,"detaching");
                    ws.reset();
                    ws.view.currentDevice(device);
                    ws.view.page("message");
                    ws.showUserMessage("Just a sec...");
                    frappe.call("galcom.compass.devices.remove_from_order",{
                        mac_address: device.mac_address,
                        // don't check WO any more // work_order: ws.view.selectedWorkOrder().name, 
                        work_order: "",
                    }).then(()=>{
                        // display "Removed"
                        ws.showUserMessage("Removed");
                    }).catch((error)=>{
                        ws.showUserMessage(error.responseJSON.exception.replaceAll("Exception: ",""));
                        console.warn("failed to detach device: ",error.responseJSON.exception);
                    });
                }else if(ws.view.workstationMode() ==="production_test"){
                    frappe.call("galcom.compass.devices.all_tests_passed",{
                        mac_address: device.mac_address,
                        set_work_order: false,
                        value: false
                    }).then(()=>{
                        ws.setDeviceStatus(device,"testing");
                        ws.reset();
                        ws.view.currentDevice(device);
                        ws.view.page("testing");
                        //remove from list of udpating devices if it happens to be there
                        ws.view.devicesUpdating.remove(d => d.mac_address === deviceName);
                        return ws.sendStationMessage({
                            type:"command",
                            command: "run_production_tests",
                            mac_address: device.mac_address
                        });
                    })
                }else if(ws.view.workstationMode() ==="test"){
                    ws.setDeviceStatus(device,"testing");
                    if(ws.view.currentDevice() != null ){ // We were in the middle of testing another device
                        console.warn("Test interrupted. orig device: "+ws.view.currentDevice().mac_address+", new device: "+device.mac_address);
                        ws.setDeviceStatus(ws.view.currentDevice(),"interrupted");
                        ws.addToSideList(ws.view.currentDevice());
                    }
                    ws.reset();

                    ws.view.currentDevice(device);

                    if( ! await ws.check_board_type(device)){
                        return;
                    }

                    ws.view.page("testing");
                    //remove from list of udpating devices if it happens to be there
                    ws.view.devicesUpdating.remove(d => d.mac_address === deviceName);


                    ws.sendStationMessage({
                        type:"command",
                        command: "run_all_tests",
                        mac_address: device.mac_address
                    });
                }else if(ws.view.workstationMode() ==="info" || ws.view.workstationMode() === "production_info"){
                    ws.reset();
                    device.workOrderDoc= ko.observable();
                    ws.view.currentDevice(device);
                    ws.view.page("device-info");

                    console.log("getting work order for "+device.work_order);
                    frappe.db.get_list("Work Order",{
                        fields: ["frequencies","audio_content","compass_configuration","sales_order","item_name"],
                        filters:{
                            name: device.work_order
                        }}).then(workOrders =>{
                            console.log("got work orders ",workOrders);
                            if(workOrders != null && workOrders.length === 1){
                                device.workOrderDoc( workOrders[0]);
                            }
                        })
                }else{
                    console.error("unknown workstationmode found: ", ws.view.workstationMode());
                }


                return device;
            }else{
                console.warn("Device not found. ",deviceName);
                ws.showUserMessage("Device "+deviceName+" was not found in ERP.");
                ws.view.page("message");
            }
        });

    }
 
    ws.messageUser = function(message){
        console.log("got message for user: "+message);
        ws.showUserMessage(message,true);
        ws.view.page("message");
    }
    ws.loadCircuitBoards =  async function(){
        const playerBoards= await frappe.db.get_list("Item",{
            fields:["item_code","_user_tags"],
            filters: {
                "item_code":["like","COM-B%"],
                "_user_tags":["like","%,Player%"]}
        });
        const radioBoards= await frappe.db.get_list("Item",{
            fields:["item_code","_user_tags"],
            filters: {
                "item_code":["like","COM-B%"],
                "_user_tags":["like","%,Radio%"]}
        });
        const boards = {};

        //console.log("loading circuit boards: ",playerBoards.concat(radioBoards));

        for(const b of playerBoards){
            boards[b.item_code] = b;
            boards[b.item_code].hasPlayer=true;
        }
        for(const b of radioBoards){
            if( boards[b.item_code] == null)
                boards[b.item_code] = b;
            boards[b.item_code].hasRadio =true;
        }
        
        console.log("final boards: ",boards);
        return boards;
    }


    ws.init = function(){

        ///// MAIN start//////////////////

        console.log("version 0.8");

        //get url parameters
        const params = new URLSearchParams(document.location.search);
        const stationName = params.get("name");
        

        if(stationName != null){
            ws.view.selectWorkstation(stationName)
            ws.view.changeMode("test"); // make sure the workstation is in the right mode
        }

        frappe.db.get_list("Compass Workstation", { fields:["name"]}).then(result =>{
            ws.view.workstations(result.map(x => x.name));
        });

        ws.loadCircuitBoards().then(boards => ws.circuitBoards = boards);

        ws.view.refreshWO();
        setInterval(ws.view.refreshWO,300000); //refresh every 5 minutes

        ko.applyBindings(ws.view);

        if (window.dev_server) {
            frappe.boot.socketio_port = '9000' //use socketio port shown when bench starts
        }
        frappe.realtime.init();
        //frappe.realtime.socket.on('eval_js', function(message) {
        frappe.realtime.on('eval_js', function(message) {
			eval(message);
		});

    

        // for testing  
        //setTimeout(async () =>{
        //    ws.view.workstationMode("test");
        //    const device = await ws.new_device("C8:C9:A3:71:71:6E");

        //    ws.check_board_type(device);

        //},2000)
        //setTimeout(() =>{
        //    ws.startTest("test_speaker");
        //    //ws.messageUser("Please plug in the headphones now");
        //    //var device = ws.view.currentDevice();
        //    //device.status = ko.observable("pending");
        //    //ws.view.devicesUpdating.push(device);
        //},3000)

    }

});

